// 
// tiny-slider.scss
// Theme Component

.tns-outer{
  position: relative;
}
.tns-item{
  touch-action: pan-y;
  user-select: none;
}
.tiny-slider{
  .item{
    position: relative;
  }
}

//
// Slider arrow
//
.tns-controls{
  [data-controls]{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: $border-radius;
    display: block;
    z-index: 9;
    text-align: center;
    transition: $transition-base;
    background: rgba($primary, .3);
    color: $white;
    border: none;
    padding: 0;
    margin: 0 5px;
    &:hover{
      background-color: $primary;
      color: $white;
    }
    i{
      line-height: inherit;
    }
  }
}

.tns-controls [data-controls='prev'] {
  left: 0;
}
.tns-controls [data-controls='next'] {
  right: 0;
}

/* rtl:raw:
.tiny-slider .tns-controls [data-controls]{
  transform: scaleX(-1) translateY(-50%);
}
.tns-visually-hidden {
  right: -10000em !important;
  left: 0 !important;
}
*/

// arrow dark
.arrow-dark{
  [data-controls] {
    background: $dark;
    color: $white;
  }
}
.arrow-white{
  [data-controls] {
    background-color: $body-bg;
    color: $body-color;
  }
}

// arrow round
.arrow-round{
  [data-controls] {
    border-radius: 50%;
  }
}

// arrow hover
.arrow-hover{
  overflow: hidden;
  [data-controls='prev']{
    left: -60px;
  }
  [data-controls='next']{
    right: -60px;
  }
  &:hover{
    [data-controls='prev']{
    left: 0px;
    }
    [data-controls='next']{
      right: 0px;
    }
  }
}

// 
// Slider dots
// 
.tns-nav{
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: auto;
  text-align: center;
  white-space: nowrap;
  [data-nav] {
    position: relative;
    display: inline-block;
    margin: 0 4px;
    padding: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px $gray-500;
    transition: $transition-base;
    border: 0;
  }
  .tns-nav-active{
    background-color: $white;
  }
}

// Dots inside
.dots-inside .tns-nav{
  top: auto;
  padding-bottom: 20px;
}

// Dots white
.dots-white .tns-nav{
  [data-nav] {
    box-shadow: inset 0 0 0 2px $white;
    transition: $transition-base;
    &:after{
      background-color: $white;
      box-shadow: 0 0 1px $white;
    }
  }
}

// Dots dark
.dots-dark .tns-nav{
  [data-nav] {
    box-shadow: inset 0 0 0 2px $dark-gray;
    transition: $transition-base;
    &:after{
      background-color: $dark-gray;
      box-shadow: 0 0 1px $dark-gray;
    }
  }
}