// Should you need to add your custom variable or you need to override our theme variables from _variables.SCSS, then we highly recommend that you add the code into this file so that whenever theme update is available and you update the theme, You will not lose your hard work :)

// Below given example is for how to overrides core variables. Uncomment the code, after compilation, You will see the color scheme changes on frontend.

// User Variables
// Uncomment below code and change primary color to your brand color


$primary:     #605ea9;
$secondary:   #474554;
$success:     #00c896;
$info:        #d5cdff;
$warning:     #f9f871;
$danger:      #a94747;


// theme colors map
$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

// theme soft colors map
$theme-soft-colors: (
  "white-soft":      rgba($white, $soft-alpha + 0.2),
  "primary-soft":    rgba($primary, $soft-alpha),
  "secondary-soft":  rgba($secondary, $soft-alpha),
  "success-soft":    rgba($success, $soft-alpha),
  "info-soft":       rgba($info, $soft-alpha),
  "warning-soft":    rgba($warning, $soft-alpha + 0.05),
  "danger-soft":     rgba($danger, $soft-alpha),
  "light-soft":      rgba($light, $soft-alpha),
  "dark-soft":       rgba($dark, $soft-alpha)
);


$link-color:                        $primary;
$link-hover-color:                  shift-color($link-color, $link-shade-percentage);

$navbar-light-hover-color:          $primary;
$navbar-light-active-color:         $primary;
$navbar-dark-hover-color:           $primary !important;
$navbar-dark-active-color:          $primary !important;

$dropdown-link-hover-color:         $primary !important;
$dropdown-link-active-color:        $primary;
$dropdown-dark-link-hover-bg:       $dropdown-link-hover-bg;
$dropdown-dark-link-active-color:   $dropdown-link-active-color;
$dropdown-dark-link-active-bg:      $dropdown-link-active-bg;

$progress-bar-bg:                   $primary;

$component-active-bg:               $primary;

$pagination-hover-color:            $white;
$pagination-hover-bg:               $primary;
$pagination-hover-border-color:     $primary;
$pagination-active-color:           $component-active-color;
$pagination-active-bg:              $component-active-bg;
$pagination-active-border-color:    $pagination-active-bg;

$list-group-active-color:           $component-active-color;
$list-group-active-bg:              $component-active-bg;
$list-group-active-border-color:    $list-group-active-bg;

$nav-pills-link-active-color:       $component-active-color;
$nav-pills-link-active-bg:          $component-active-bg;

$input-focus-border-color:          $primary;
$form-check-input-focus-border:     $input-focus-border-color;
$form-switch-focus-color:           $input-focus-border-color;
$form-select-focus-border-color:    $input-focus-border-color;

$form-check-input-checked-bg-color:       $component-active-bg;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color;
$form-check-input-indeterminate-color:          $component-active-color;
$form-check-input-indeterminate-bg-color:       $component-active-bg;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color;

$form-switch-focus-color:     $input-focus-border-color;
$form-switch-focus-bg-image:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$btn-link-color:              $link-color;
$btn-link-hover-color:        $link-hover-color;

$form-range-thumb-bg:         $component-active-bg;
$form-range-thumb-active-bg:  tint-color($component-active-bg, 70%);

// Accordion
$accordion-button-active-bg:              tint-color($component-active-bg, 90%);
$accordion-button-active-color:           shade-color($primary, 10%);
$accordion-icon-color:                    $accordion-color;
$accordion-icon-active-color:             $accordion-button-active-color;
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

// Font Families

$font-family-base:           'Poppins', Helvetica, sans-serif;
$font-family-heading:        'Poppins', Helvetica, sans-serif;  

