// 
// buttons.scss
// Extended from Bootstrap
// 

//
// Bootstrap Overrides
//

.btn-link{
  padding: 0;
}

// 
// Additional styles for theme
// 

.btn{
  outline: 0;
}
.input-group .btn{
  margin-bottom: 0;
}
.btn-xs{
  padding: 0.4rem 0.6rem;
  font-size: 0.6rem;
  line-height: 1.2;
  border-radius: $btn-border-radius-sm;
}

// button light
.btn-light{
  &.active, &:active, &:focus, &:hover{
    background: $light;
  }
}

// button white
.btn-white{
  background: $white;
  color: $gray-800;
  &.active, &:active, &:focus, &:hover{
    background: $gray-200;
    color: $gray-800;
  }
}

// button outline light
.btn-outline-light{
  background: transparent;
  border-color: $gray-400;
  color: $body-color;
}
.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-check:checked + .btn-outline-light{
  background: $gray-400;
  border-color: $gray-400;
  color: $gray-800;
}

// button soft
@mixin button-soft-variant($color, $soft-background: rgba($color, .1)){
  color: $color;
  background-color: $soft-background;
  &:hover{
    color: color-contrast($color);
    background-color: $color;
  }
}
@each $color, $value in $theme-colors{
  .btn-#{$color}-soft{
    @include button-soft-variant($value);
  }
} 

// button soft hover
@mixin button-soft-variant-hover($color, $soft-background: rgba($color, .1)){
  &:hover {
    color: $color !important;
    background-color: $soft-background;
  }
}
@each $color, $value in $theme-colors{
  .btn-#{$color}-soft-hover:hover{
    @include button-soft-variant-hover($value);
  }
}

// button dashed
.btn-dashed{
  background: 
  linear-gradient(90deg, $border-color 50%, transparent 50%),
  linear-gradient(0deg, $border-color 50%, transparent 50%),
  linear-gradient(90deg, $border-color 50%, transparent 50%),
  linear-gradient(0deg, $border-color 50%, transparent 50%);
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-size: 10px 2px, 2px 10px, 10px 2px, 2px 10px;
  background-position: left top, right top, left bottom, left top;
  padding: $btn-padding-y, $btn-padding-x;
    &:hover{
      animation: border-dance 4s infinite linear;
    }
  }
  @keyframes border-dance{
    0%{
      background-position: left top, right top, right bottom, left bottom;
    }
    100% {
      background-position: right top, right bottom, left bottom, left top;
    }
  }
 
// button loader
.btn-loader{
  position: relative;
  text-align: center;
  .load-icon{
    display: none;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .load-text{
    opacity: 1;
    visibility: visible;
  }
}
.btn-loader.active{
  .load-text{
    opacity: 0;
    visibility: hidden;
  }
  .load-icon{
    display: block;
  }
}

// Spinner dots
.spinner-dots{
  align-items: center;
  display: flex;
  justify-content: center;
  }
  .spinner-dot{
  background-color: $gray-500;
  display: inline-block;
  height: 4px;
  margin: 2px;
  border-radius: 50%;
  width: 4px;
  }
  @keyframes dot{
  0% { background-color: $gray-500; transform: scale(1); }
  50% { background-color: $light-gray; transform: scale(1.3); }
  100% { background-color: $gray-500; transform: scale(1); }
}

// button link loader active
.btn-link-loader.active {
  .spinner-dots { 
    .spinner-dot { 
      animation: dot ease-in-out 1s infinite;
    }
    .spinner-dot:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    .spinner-dot:nth-of-type(3) {
    animation-delay: 0.3s;
    }
  }
}
