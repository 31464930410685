// 
// pagination.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

// For better breakpoint
.pagination{
  .page-item{
    margin-bottom: 5px;
    float: left;
  }
}

// pagination bordered
.pagination-light{
  .page-item{
    margin: 2px;
  }
  .page-link{
    border-color: $light;
    background-color: $light;
    border-radius: $border-radius-sm;
    &:hover{
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
    }
  }
}